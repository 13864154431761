import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  inject,
  input,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  TemplateRef,
  viewChild,
  ViewChild,
} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {ResFile} from '../../models';
import {MatTooltip} from '@angular/material/tooltip';
import {NgClass, NgTemplateOutlet} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {TruncatePipe} from '../../pipes/truncate-string.pipe';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import {ImageCroppedEvent, ImageCropperComponent} from 'ngx-image-cropper';
import {Router} from '@angular/router';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-upload-input',
  templateUrl: './upload-input.component.html',
  styleUrls: ['./upload-input-component.scss'],
  standalone: true,
  imports: [
    MatTooltip,
    NgClass,
    TranslateModule,
    TruncatePipe,
    MatDialogModule,
    ImageCropperComponent,
    NgTemplateOutlet,
    MatRadioButton,
    MatRadioGroup,
    ReactiveFormsModule,
  ],
})
export class UploadInputComponent implements OnInit, OnChanges, OnDestroy {
  @ContentChild(TemplateRef) toggleTemplateRef: TemplateRef<any>;

  // Services
  private domSanitizationService: DomSanitizer = inject(DomSanitizer);
  private dialog = inject(MatDialog);
  private router = inject(Router);

  // Inputs / Outputs
  imagePreviewSize = input<{width: string; height: string}>({
    width: '100%',
    height: '100%',
  });
  // Default image size recommended
  imageSizeRecommended = input<{width: number; height: number} | null>(null);
  imageSizeRequired = input<{width: number; height: number}>({
    width: 0,
    height: 0,
  });
  showImagePlaceholder = input<boolean>(false);
  showImagePreview = input<boolean>(false);
  isCroppingImage = input<boolean>(false);
  croppingImageTitle = input<string>('Cropping Image');
  @Input() label: string;
  @Input() placeholder: string;
  @Input() imgLabel: string;
  @Input() parentFile: File;
  @Input() parentFileName: string;
  @Input() disabled: boolean;
  @Input() noDispatch?: boolean = false; // If true -> do not emit the resFile output
  @Input() labelInfo: string;
  allowSvg = input<boolean>(false);
  allowIco = input<boolean>(false);
  @Output() resFile: EventEmitter<ResFile | null> =
    new EventEmitter<ResFile | null>();
  @Output() hasSizeError: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Children
  @ViewChild('imageInput') imageInput: ElementRef;

  // Properties
  public imageName: string | null;
  public imgSrc = signal<SafeUrl | string | null>(null);
  public sizeError = signal<boolean>(false);

  // cropping image
  public imageChangedEvent: Event | null = null;
  public croppingImageDialogTemplate =
    viewChild<TemplateRef<any>>('croppingDialog');
  public croppingImageDialogRef: MatDialogRef<any>;
  public backgroundCroppingColor = 'rgba(255, 255, 255, 0)';
  public croppedImage: ImageCroppedEvent;
  public alignImage = 'center' as const;
  public aspectRatio = 4 / 3;
  public croppedImageName = '';
  public fileTypes = '.jpg, .jpeg, .png';
  public cropType = new FormControl('free');
  requiredSize = signal<{width: number; height: number}>({
    height: 0,
    width: 0,
  });
  public cropperDimensions = {width: 0, height: 0};

  private subs = new Subscription();

  ngOnInit(): void {
    if (this.allowSvg()) {
      this.fileTypes += ', .svg';
    }

    if (this.allowIco()) {
      this.fileTypes += ', .ico';
    }

    this.subs.add(
      this.cropType.valueChanges.subscribe((value): void => {
        this.requiredSize.set(
          value === 'aspect'
            ? this.imageSizeRequired()
            : {
                height: 0,
                width: 0,
              }
        );
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.parentFile) {
      if (this.parentFile) {
        setTimeout(() => {
          const reader = new FileReader();
          reader.readAsDataURL(this.parentFile!);
          reader.onload = (): void => {
            if (!this.sizeError()) {
              const mimeType = this.getMimeTypeFromFileName(
                this.parentFile?.name
              );
              const base64Data = reader.result?.toString().split(',')[1];
              const dataUrl = `data:${mimeType};base64,${base64Data}`;

              this.imgSrc.set(
                this.domSanitizationService.bypassSecurityTrustUrl(dataUrl)
              );
            }
            if (!this.noDispatch) {
              if (this.parentFile) {
                this.imageName = this.parentFile?.name;
                this.resFile.emit({
                  fileName: this.parentFile.name,
                  fileSource: this.imgSrc()!,
                  fileFile: this.parentFile,
                });
              }
            }
          };
        }, 0);
      }
    } else {
      this.imageName = changes.parentFileName?.currentValue;
    }
  }

  handleFileInput(event: Event): void {
    event.stopPropagation();
    this.imageChangedEvent = event;
    const target = event.target as HTMLInputElement;
    const files: FileList | null = target.files;
    if (files && files.length !== 0) {
      const mimeType = files.item(0)?.type || '';
      if (mimeType?.match(/image\/*/) === null) {
        return;
      }

      const imageMimeTypeRegex = this.allowSvg()
        ? this.allowIco()
          ? /^(image\/jpeg|image\/jpg|image\/png|image\/svg\+xml|image\/x-icon)$/i
          : /^(image\/jpeg|image\/jpg|image\/png|image\/svg\+xml)$/i
        : this.allowIco()
          ? /^(image\/jpeg|image\/jpg|image\/png|image\/x-icon)$/i
          : /^(image\/jpeg|image\/jpg|image\/png)$/i;

      if (!imageMimeTypeRegex.test(mimeType)) {
        console.error('Image file not supported.');
        return;
      }

      const imgFile = files.item(0);
      const imgName = imgFile?.name || '';
      const imgFormat = this.getImageFormat(imgFile!);
      let allowCrop = this.isCroppingImage();

      if (
        (imgFormat === 'image/svg+xml' && this.allowSvg()) ||
        (imgFormat === 'image/x-icon' && this.allowIco())
      ) {
        allowCrop = false;
      }

      const reader: FileReader = new FileReader();
      reader.readAsDataURL(imgFile!);
      reader.onload = (): void => {
        if (!allowCrop) {
          const imgSrc = reader.result;
          this.noDispatch = false;
          this.imageName = imgName;
          if (!this.allowSvg() && !this.allowIco()) {
            if (this.imageSizeRecommended() && imgSrc) {
              this.validateImageResolution(imgSrc as string);
            }
          }
          !this.noDispatch &&
            this.resFile.emit({
              fileName: imgName,
              fileSource: imgSrc,
              fileFile: imgFile,
            });
        }
        if (allowCrop) {
          this.croppedImageName = imgName;
        }
      };
      if (allowCrop) {
        this.openCroppingDialog();
      }
    }
  }

  validateImageResolution(imageSource: string): void {
    const img = new Image();
    img.src = imageSource as string;
    const sizeRecommended = {...this.imageSizeRecommended()};
    const widthRecommended = sizeRecommended.width as number;
    const heightRecommended = sizeRecommended.height as number;
    img.onload = (): void => {
      if (img.width > widthRecommended || img.height > heightRecommended) {
        this.sizeError.set(true);
        this.hasSizeError.emit(true);
        this.imageName = null;
        this.imgSrc.set(null);
        this.resFile.emit(null);
        this.imageInput.nativeElement.value = '';
      }
      if (img.width <= widthRecommended && img.height <= heightRecommended) {
        this.sizeError.set(false);
        this.hasSizeError.emit(false);
        this.imgSrc.set(
          this.domSanitizationService.bypassSecurityTrustUrl(
            imageSource as string
          )
        );
      }
    };
  }

  removeImage(): void {
    this.imageName = null;
    this.imgSrc.set(null);
    this.resFile.emit(null);
    this.imageInput.nativeElement.value = '';
  }

  // cropping image
  openCroppingDialog(): void {
    this.croppingImageDialogRef = this.dialog.open(
      this.croppingImageDialogTemplate() as TemplateRef<any>,
      {
        minWidth: '50vw',
        width: '50vw',
        maxWidth: '70vw',
        disableClose: true,
      }
    );

    const dialogSub = this.router.events.subscribe(() => {
      this.croppingImageDialogRef.close();
    });

    this.croppingImageDialogRef.afterClosed().subscribe(() => {
      dialogSub.unsubscribe();
    });
  }

  closeCroppingDialog(): void {
    this.imageInput.nativeElement.value = '';
    this.croppingImageDialogRef.close();
    this.cropType.setValue('free');
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event;
  }

  buildAndSendCroppedImage(): void {
    const croppedFile = new File(
      [this.croppedImage.blob as Blob],
      this.croppedImageName || 'cropped_image.png',
      {type: (this.croppedImage.blob as Blob).type}
    );
    this.imgSrc.set(
      this.domSanitizationService.bypassSecurityTrustUrl(
        this.croppedImage.objectUrl as string
      )
    );
    this.imageName = this.croppedImageName;
    this.resFile.emit({
      fileName: this.imageName || 'cropped_image.png',
      fileSource: this.imgSrc(),
      fileFile: croppedFile,
    });
    this.closeCroppingDialog();
  }

  getImageFormat(file: File): string {
    return file.type;
  }

  onCropperChange(event: any): void {
    if (event) {
      this.cropperDimensions.width = parseFloat(
        (event.x2 - event.x1).toFixed(2)
      );
      this.cropperDimensions.height = parseFloat(
        (event.y2 - event.y1).toFixed(2)
      );
    }
  }

  private getMimeTypeFromFileName(fileName: string): string {
    const extension = fileName.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'svg':
        return 'image/svg+xml';
      default:
        return 'application/octet-stream';
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
