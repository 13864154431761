@if (label) {
  <div class="d-flex justify-content-between align-items-center mb-1">
    <label class="mb-0">
      {{ label }}
      @if (labelInfo) {
        <i
          class="fa-regular fa-circle-info ms-1"
          matTooltip="{{ labelInfo }}"
          matTooltipClass="wsm-tooltip"
          matTooltipPosition="after"
        >
        </i>
      }
    </label>
    @if (toggleTemplateRef) {
      <ng-container *ngTemplateOutlet="toggleTemplateRef"></ng-container>
    }
  </div>
}

<div class="d-flex flex-column align-items-center">
  @if (imgSrc() !== null && showImagePreview()) {
    <img
      id="customImgPreview"
      [src]="imgSrc()"
      [alt]="imageName"
      [style]="{
        'height': imagePreviewSize().height,
        'width': 'auto',
        'max-width': imagePreviewSize().width,
        'object-fit': 'contain',
      }"
    />
  }
  @if (imgSrc() === null && showImagePlaceholder()) {
    <span class="image-placeholder">
      <i class="fa-regular fa-image fa-3x"></i>
    </span>
  }
  <div
    [ngClass]="{'disabled-input-file': disabled}"
    (click)="imageInput.click()"
    class="wsm-input-group-prepend c-pointer w-100"
  >
    <i
      [ngClass]="{'d-none': imageName}"
      class="fa-regular fa-arrow-up-from-line upload-icon"
    ></i>

    <div [ngClass]="{'bg-transparent': imageName}" class="wsm-input w-100">
      @if (!imageName) {
        <span class="upload-placeholder">{{ placeholder }}</span>
      } @else {
        <span class="image-text-wrapper">
          <i
            (click)="$event.stopPropagation(); removeImage()"
            class="fa-regular fa-xmark"
          ></i>
          <span>{{ imageName! | truncate: 15 }}</span>
        </span>
      }
    </div>
  </div>
  @if (imageSizeRecommended()) {
    <span class="wsm-text-grayed align-self-start italic-text ps-1">
      <small
      >{{
          "common.upload_input_component.recommended_dimensions" | translate
        }}&nbsp;
        {{
          imageSizeRecommended()?.width +
          "x" +
          imageSizeRecommended()?.height +
          "px"
        }}
      </small>
    </span>
  }
  @if (sizeError()) {
    <span class="invalid-info">
      {{
        "common.upload_input_component.required_dimensions_error" | translate
      }}
    </span>
  }
</div>
<input
  #imageInput
  [hidden]="true"
  type="file"
  [accept]="fileTypes"
  (change)="handleFileInput($event)"
/>

@if (isCroppingImage()) {
  <ng-template #croppingDialog>
    <header class="d-flex justify-content-between align-items-center">
      <h4>{{ croppingImageTitle() }}</h4>
      <button
        (click)="closeCroppingDialog()"
        data-cy-cropping-dialog-btnClose="close-dialog"
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
      >
        <i class="fa-regular fa-xmark"></i>
      </button>
    </header>
    <mat-dialog-content>
      <div class="row mb-3 mx-0">
        <div class="col-12">
          <mat-radio-group [formControl]="cropType" id="imageCropOption">
            <mat-radio-button value="free" class="me-3">
              {{ "common.upload_input_component.free_crop" | translate }}
            </mat-radio-button>
            <mat-radio-button value="aspect">
              {{
                "common.upload_input_component.maintain_aspect_ratio"
                  | translate
              }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div
        class="d-flex flex-column align-items-center justify-content-center gap-2"
      >
        <div class="w-50" #cropContainer>
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [backgroundColor]="backgroundCroppingColor"
            [cropperStaticWidth]="requiredSize()?.width"
            [cropperStaticHeight]="requiredSize()?.height"
            [onlyScaleDown]="cropType?.value === 'aspect'"
            [alignImage]="alignImage"
            [aspectRatio]="aspectRatio"
            [hideResizeSquares]="cropType?.value === 'aspect'"
            [maintainAspectRatio]="cropType?.value === 'aspect'"
            (imageCropped)="imageCropped($event)"
            (cropperChange)="onCropperChange($event)"
          />
        </div>
        @if (cropType.value === "aspect") {
          <p class="wsm-text-grayed">
            {{ "common.upload_input_component.cropping_selection" | translate }}
          </p>
        }
        @if (cropType.value === "free") {
          <p>
            {{ "common.upload_input_component.current_crop_size" | translate }}:
            {{ cropperDimensions.width }} x {{ cropperDimensions.height }} px
          </p>
        }
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-end gap-2">
      <button
        (click)="closeCroppingDialog()"
        class="wsm-btn wsm-btn-outline"
        cy-data-btn="previous-step"
      >
        {{ "common.upload_input_component.cancel_cropping" | translate }}
      </button>
      <button
        (click)="buildAndSendCroppedImage()"
        class="wsm-btn wsm-btn-primary"
        cy-data-btn="next-step"
      >
        {{ "common.upload_input_component.accept_cropping" | translate }}
      </button>
    </mat-dialog-actions>
  </ng-template>
}
